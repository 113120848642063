<template>
  <div class="py-2 flex flex-wrap justify-center md:justify-start">
    <button
      v-for="logo in logos"
      :key="logo.id"
      type="button"
      :disabled="disableSelect"
      class="border border-line rounded-md min-w-36 h-32 md:h-36 w-32 md:w-36 m-1.5 md:m-2 overflow-hidden !p-0 relative justify-self-center"
      :class="{ '!border-primary !border-2': selectedLogo === logo.id }"
      @click="selectedLogo = logo.id"
    >
      <img
        class="w-full h-full object-contain"
        :src="logo.logoUrl"
        alt="Logo"
      />
      <RemoveLogoConfirmModal
        v-if="props.enableDelete"
        @submit="deleteLogo(logo.id)"
      />
    </button>
    <FileInput
      :accepted-types="AcceptedFileTypes.IMAGE"
      :image-size-limit="SOCIAL_POST_IMAGE_SIZE_LIMIT"
      class="rounded-md min-w-36 h-32 md:h-36 w-32 md:w-36 m-1 md:m-2 justify-self-center"
      :title="$t('add-logo-modal.upload-file')"
      hide-edit
      @update:model-value="handleLogoUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { AcceptedFileTypes } from '~/constants/files';
import { SOCIAL_POST_IMAGE_SIZE_LIMIT } from '~/pages/my-posts/my-posts.constants';
import { FileInput } from '~/components/file-input';
import { useQuadrantsStore } from '~/store/quadrants-store';
import { apiCreateAccountLogo } from '~/api/accounts';
import { RemoveLogoConfirmModal } from '~/pages/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal';
import { MAX_LOGOS_LIMIT } from '~/pages/my-posts/_components/add-logo-content/add-logo.content.constants';
import { notify } from '@kyvg/vue3-notification';
import { NotificationType } from '~/components/notification';

type Props = {
  disableSelect?: false;
  enableDelete?: false;
};

const props = defineProps<Props>();

const { t } = useI18n();
const selectedLogo = defineModel<string | number>({ required: true });

const quadrantsStore = useQuadrantsStore();
const { logos } = storeToRefs(quadrantsStore);

const handleLogoUpdate = async (logoUrl: string) => {
  if (logos.value.length >= MAX_LOGOS_LIMIT) {
    notify({
      group: NotificationType.ERROR,
      title: t('add-logo-modal.limit-error'),
    });

    return;
  }

  const logo = await apiCreateAccountLogo({
    logoUrl,
  });

  quadrantsStore.addLogo(logo);

  if (!props.disableSelect) {
    selectedLogo.value = logo.id;
  }
};

const deleteLogo = async (logoId: number | string) => {
  await useLoader({
    async action() {
      await quadrantsStore.deleteLogo(logoId);
    },
  });
};
</script>
